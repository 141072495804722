import { defineStore } from 'pinia';
import { computed, ref, type Ref, type UnwrapRef } from 'vue';
import { Dayjs } from 'dayjs';
import type { LocationQuery } from 'vue-router';
import type {
  compareMode,
  GraphFilters,
  GroupBy,
  StatisticFilters,
} from '@analytics/types';
import { useHelpers } from '~/composables/useHelpers';

interface locationOption {
  name: string;
  value: null | number;
}

export const useAnalyticsStore = defineStore('analyticsStore', () => {
  const route = useRoute();
  const { updateRouteQueryParams } = useHelpers();
  const dayjs = useDayjs();

  const query = ref<undefined | LocationQuery>(undefined);

  const city = ref<number | null>(
    route.query.city ? Number(route.query.city) : null,
  );
  const area = ref<number | null>(
    route.query.area ? Number(route.query.area) : null,
  );

  const allCitiesOptionEnabled = ref(true);

  const dateRange: Ref<Dayjs[]> = ref<Dayjs[]>([
    route.query.dateFrom
      ? dayjs(String(route.query.dateFrom))
      : dayjs().subtract(30, 'days'),
    route.query.dateTo
      ? dayjs(String(route.query.dateTo))
      : dayjs().subtract(1, 'day'),
  ]);

  const compareMode: Ref<compareMode> = ref(
    route.query.compareMode
      ? (String(route.query.compareMode) as compareMode)
      : null,
  );

  const groupBy: Ref<UnwrapRef<GroupBy>> = ref<GroupBy>(
    route.query.groupBy ? (route.query.groupBy as GroupBy) : 'day',
  );

  const cities: Ref<UnwrapRef<Domain.Analytics.DataObjects.CityData[]>> = ref<
    Domain.Analytics.DataObjects.CityData[]
  >([]);

  const ALL_CITY_OPTION: locationOption = {
    name: 'All cities',
    value: null,
  };

  const ALL_AREA_OPTION: locationOption = {
    name: 'All areas',
    value: null,
  };

  const areaOptions = computed(() => {
    if (!city.value || !cities.value) return [ALL_AREA_OPTION];

    const selectedCity = cities.value.find(
      (cityOpt) => cityOpt.id === city.value,
    );

    if (!selectedCity) return [ALL_AREA_OPTION];

    return [
      ALL_AREA_OPTION,
      ...selectedCity.areas.map((areaOpt) => ({
        value: areaOpt.id,
        name: areaOpt.name,
      })),
    ];
  });

  const cityOptions = ref<locationOption[]>([ALL_CITY_OPTION]);

  const statisticFilters = computed<StatisticFilters>(() => {
    const [dateFrom, dateTo] = dateRange.value;
    return {
      dateFrom: dayjs(dateFrom).format('YYYY-MM-DD'),
      dateTo: dayjs(dateTo).format('YYYY-MM-DD'),
      cityId: city.value,
      areaId: area.value,
      compareMode: compareMode.value,
    };
  });

  const graphFilters = computed<GraphFilters>(() => {
    return {
      ...statisticFilters.value,
      groupBy: groupBy.value,
    };
  });

  function updateCityOptions(
    includeAllCities: Ref<boolean>,
    entries: locationOption[],
  ) {
    const options: locationOption[] = [];

    if (includeAllCities.value) {
      options.push(ALL_CITY_OPTION);
    }

    options.push(...entries);
    cityOptions.value.push(...options);
  }

  function updateSelectableOptions() {
    // Note: splice and push has to be used or only the ALL_CITY_OPTION is passed through to other stores
    cityOptions.value.splice(0, cityOptions.value.length);

    updateCityOptions(
      allCitiesOptionEnabled,
      cities.value.map((city) => {
        return {
          name: city.name,
          value: city.id,
        };
      }),
    );

    /**
     * If city value is not already set, default to first in list
     */
    if (!city.value && cities.value.length > 0) {
      city.value = allCitiesOptionEnabled.value ? null : cities.value[0].id;
    }
  }

  async function getCities(): Promise<void> {
    const { data } = await useApiFetch<Domain.Analytics.DataObjects.CityData[]>(
      useZiggy('agents.analytics.cities'),
      { method: 'GET' },
    );

    if (
      data.value &&
      Array.isArray(data.value) // Users with no properties will not get any city data
    ) {
      cities.value = data.value;

      updateSelectableOptions();
    } else {
      // For new users with no properties, just show th default in the dropdown
      cityOptions.value.splice(0, cityOptions.value.length);
      if (allCitiesOptionEnabled.value) {
        cityOptions.value.push(ALL_CITY_OPTION);
      }
    }
  }

  function disableAllCitiesOption() {
    if (allCitiesOptionEnabled.value) {
      allCitiesOptionEnabled.value = false;
      updateSelectableOptions();
    }
  }

  function enableAllCitiesOption() {
    if (!allCitiesOptionEnabled.value) {
      allCitiesOptionEnabled.value = true;
      updateSelectableOptions();
    }
  }

  // Watch for change in city and reset the area to 0
  watch(
    [() => city],
    (): void => {
      area.value = null;
    },
    { deep: true },
  );

  // Update query parameters on filter changes
  watch(
    [() => dateRange, () => groupBy, () => area, () => city, () => compareMode],
    (): void => {
      const [dateFrom, dateTo] = dateRange.value;

      query.value = {
        area: area.value?.toString() || null,
        city: city.value?.toString() || null,
        dateFrom: dayjs(dateFrom).format('YYYY-MM-DD'),
        dateTo: dayjs(dateTo).format('YYYY-MM-DD'),
        groupBy: groupBy.value,
      };

      if (compareMode.value) {
        query.value.compareMode = compareMode.value;
      }

      updateRouteQueryParams(query.value);
    },
    { deep: true },
  );

  getCities();

  return {
    query,
    area,
    areaOptions,
    city,
    compareMode,
    cityOptions,
    dateRange,
    groupBy,
    statisticFilters,
    graphFilters,
    disableAllCitiesOption,
    enableAllCitiesOption,
  };
});
